.card-container{
    width:48%;
    height:150px;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    cursor:pointer;
    display:flex;
    align-items:center;
    margin-bottom:20px;
    /*opacity:0;*/
}
.card-container .card-image-container{
    width:140px;
}
.card-container .card-title-container{
    width:calc(100% - 140px);
    text-align: center;
    padding:20px 10px;
}
.card-image-container .card-image{
    width:120px;
    height:120px;
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;
    margin-left:20px;
}

.past4-panel .card-container{
    margin-bottom:40px;
}


/*ESTILO DE VERTICAL CARD*/
.vertical-card{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:30%;
    padding-bottom:20px;
    height:330px;
    cursor:pointer;
}
.vertical-card .card-image-container .card-image {
    margin-left: 0 !important;
    margin-top:20px;
    margin-bottom:20px;
    width:150px;
    height:150px;
}
.vertical-card .card-title{
    line-height: 50px;
    padding-left: 20px;
    padding-right: 20px;
}
/*las cards de pasado tienen q tener opacity en 1*/
.past4-section1 .card-container{
    opacity:1;
}

/*responsive big advices*/
@media screen and (min-height:990px) and (min-width:1600px){

    .card-container{
        height:200px;
    }
    .card-container .card-image-container{
        width:180px;
    }
    .card-container .card-title-container{
        width:calc(100% - 180px);
    }
    .card-image-container .card-image{
        width:160px;
        height:160px;
    }
    .vertical-card{
        height:400px;
    }
    .vertical-card .card-image-container .card-image {
        width:180px;
        height:180px;
    }
    
  }

  /*para caso 1280 x 600*/
@media screen and (max-width:1300px) and (max-height:620px){
    .card-image-container .card-image {
        width: 100px;
        height: 100px;
    }
    .card-container {
        height: 130px;
    }
}

  /*RESPONSIVE MOBILE*/
  @media screen and  (max-width:1200px){
    .card-container{
        width:100%;
        /*opacity:1;*/
    }
    .vertical-card{
        width:70vw;
        margin:auto;
    }
  }

  /*RESPONSIVE LANDSCAPE*/
  @media screen and (max-width:1200px) and (orientation: landscape) {
    .card-container {
        width: 49%;
    }
    .vertical-card {
        width: 30%;
    }
}