.static-card-title-container{
    /*opacity:0;*/
    width:32vw;
    margin:auto;
    display:flex;
    flex-direction: column;
}
.static-card-img{
    height:19vw;
    width:32vw;
    margin:auto;
    margin-top:8vh;
    /*opacity:0;*/
    margin-bottom:3vh;
}
.static-card-title-container .enter-arrow{
    align-self: flex-end;
    margin-top: 20px;
}


/*las static cards del pasado, inician con opacity 1 y terminan con opacity 0 a diferencia de las del futuro*/
.past1-card-container .static-card-title-container,
.past1-card-container .static-card-img{
    opacity:1;
}

/*responsive big advices*/
@media screen and (min-height:990px) and (min-width:1600px){

    .static-card-img{
        margin-top:10vh;
    }
  }
 
  /*RESPONSIVE MOBILE*/
  @media screen and (max-width:1200px){
    .static-card-img {
        height: 70vw;
        width: 90vw;
        margin-top: 60px;
        /*opacity: 1;*/
        margin-bottom:20px;
        min-height: 60vw;
    }
    .static-card-title-container {
        width: 90vw;
        margin: auto;
        padding-bottom:60px;
        /*opacity:1;*/
    }
  }

  /*para caso 1280 x 600*/
@media screen and (max-width:1300px) and (max-height:620px){
    .static-card-img {
        height: 17vw;
        margin-top:3vh;
    }
}

/*RESPONSIVE LANDSCAPE*/
@media screen and (max-width:1200px) and (orientation: landscape) {
    .static-card-img {
        height: 30vw;
        width: 50vw;
        margin-top: 60px;
        /*opacity: 1;*/
        margin-bottom:20px;
        min-height: 30vw;
    }
}